import { defineStore, acceptHMRUpdate } from 'pinia'

const store = defineStore('DamageReportFilterStore', {
  state: () => {
    return {
      filter: {
        dateStart: null,
        dateEnd: null,
        status: ['new', 'processing', 'done']
      },
      appliedFilter: '',
      table: {
        currentPage: 1,
        itemsPerPage: 25,
        sortBy: null,
        sortDesc: false
      }
    }
  },
  getters: {
    hasChanges: (state) => {
      return JSON.stringify(state.filter) !== JSON.stringify(state.appliedFilter)
    }
  },
  actions: {
    filterApplied() {
      this.appliedFilter = { ...this.filter }
    }
  },
  persist: true,
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot))
}

export const useDamageReportFilterStore = store

