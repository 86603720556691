<template>
  <div>
    <BasicEditContainer
      ref="editor"
      :data-loaded="dataLoaded"
      v-if="dataLoaded"
      :title="t('title')"
      :save-action="saveDamageReport"
      @saved="onSaved"
      :delete-action="deleteDamageReport"
      @deleted="onDeleted"
      @cancel="cancel"
      :allow-delete="true"
      :object-type="t('object-type')"
      :object-name="item.subject"
      :has-changes="hasChanges"
    >
      <template v-slot:default>
        <v-card elevation="0">
          <v-card-title>{{ t('group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
              :label="t('category-label')"
              v-model="item.category_name"
              filled
              hide-details
              :disabled="true"
            ></v-text-field>

            <v-text-field
              class="mt-4"
              :label="t('subject-label')"
              v-model="item.subject"
              filled
              hide-details
              :disabled="true"
            ></v-text-field>

            <v-text-field
              class="mt-4"
              :label="t('user-label')"
              v-model="item.user_name"
              filled
              hide-details
              :disabled="true"
            ></v-text-field>

            <v-textarea
              class="mt-4"
              :label="t('object-label')"
              v-model="item.object_combined"
              rows="2"
              filled
              hide-details
              :disabled="true"
            ></v-textarea>

            <v-text-field
              v-if="item.field_name"
              class="mt-4"
              :label="item.field_name"
              v-model="item.field_value"
              filled
              hide-details
              :disabled="true"
            ></v-text-field>

            <v-textarea
              class="mt-4"
              :label="t('description-label')"
              v-model="item.description"
              auto-grow
              rows="2"
              filled
              hide-details
              :disabled="true"
            ></v-textarea>

            <v-textarea
              class="mt-4"
              :label="t('additional-infos-label')"
              v-model="item.additional_infos"
              auto-grow
              rows="2"
              filled
              hide-details
              :disabled="true"
            ></v-textarea>
          </v-card-text>
        </v-card>

        <v-card elevation="0" v-if="item.images" class="sp-mar-top-3">
          <v-card-title>{{ t('images.group-title') }}</v-card-title>
          <v-card-text class="pa-2">
            <loading v-if="imagesLoading" class="my-2"></loading>
            <div v-else class="ma-2">{{ t('images.count') + imageData.length }}</div>
            <div class="dr-image-wrapper" :class="[{'show': showImages }]" v-masonry="'images'" transition-duration="0s" item-selector=".dr-image">
              <div v-masonry-tile v-for="(image, index) in imageData" :key="index" class="dr-image">
                <v-img :src="image" @load="countLoadedImages"></v-img>
                <v-btn
                  color="accent"
                  elevation="0"
                  outlined
                  @click="downloadImage(index)"
                >
                  <v-icon :title="t('images.download')">mdi-tray-arrow-down</v-icon>
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
        
      </template>
      
      <template v-slot:meta>
        <v-card elevation="0">
          <v-card-title>{{ t('status.group-title') }}</v-card-title>
          <v-card-text>
            <v-radio-group v-model="item.status" class="pt-0 status-list">
              <div class="d-flex justify-space-between align-center">
                <v-radio
                  class="mb-0"
                  :label="t('status.new')"
                  value="new"
                ></v-radio>
                <status-icon-widget status="new" :hide-text="false"></status-icon-widget>
              </div>

              <div class="d-flex justify-space-between align-center">
                <v-radio
                  class="mb-0"
                  :label="t('status.processing')"
                  value="processing"
                ></v-radio>
                <status-icon-widget status="processing" :hide-text="false"></status-icon-widget>
              </div>

              <div class="d-flex justify-space-between align-center">
                <v-radio
                  class="mb-0"
                  :label="t('status.done')"
                  value="done"
                ></v-radio>
                <status-icon-widget status="done" :hide-text="false"></status-icon-widget>
              </div>

              <div class="d-flex justify-space-between align-center">
                <v-radio
                  class="mb-0"
                  :label="t('status.archived')"
                  value="archived"
                ></v-radio>
                <status-icon-widget status="archived" :hide-text="false"></status-icon-widget>
              </div>
            </v-radio-group>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('comment.group-title') + '*' }}</v-card-title>
          <v-card-text>
            <v-textarea
              :label="t('comment.comment-label')"
              v-model="item.comment"
              auto-grow
              rows="4"
              filled
              hide-details="auto"
              :rules="[
                () => !!item.comment || t('comment.required'),
              ]"
            ></v-textarea>
          </v-card-text>
        </v-card>
      </template>

    </BasicEditContainer>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import { Buffer } from 'buffer'
import loading from '@/components/layouts/Loading'
import toolbar from '@/components/layouts/Navigation'
import moment from 'moment/moment'
import CancelButtonDialog from '@/components/layouts/CancelButtonDialog'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'
import StatusIconWidget from '@/components/layouts/StatusIconWidget'
import { VueMasonryPlugin } from 'vue-masonry'
import Vue from 'vue'

Vue.use(VueMasonryPlugin)

export default {
  name: 'DamageReportEdit',
  components: {
    BasicEditContainer,
    CancelButtonDialog,
    loading,
    toolbar,
    StatusIconWidget,
    VueMasonryPlugin
  },
  data() {
    return {
      itemId: null,
      item: null,
      dataLoaded: false,
      approvalComment: '',
      errorLoading: false,
      imageData: null,
      imagesLoading: false,
      showImages: false,
      loadedImages: 0,
      dataOriginal: null
    }
  },
  created() {
    this.itemId = this.$route.params.id
    this.loadData()
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.item) !== JSON.stringify(this.dataOriginal)
    }
  },
  methods: {
    t: function (key) {
      return this.$t('damage-report.edit.' + key)
    },
    async loadData() {
      try {
        let response = await HTTP.get('damage-report/' + this.itemId)
        this.item = response.data
        this.dataOriginal = JSON.parse(JSON.stringify(this.item))
        this.dataLoaded = true
        this.loadImages()
      } catch (err) {
        this.$root.infoNotification.showError(err.message)
        this.errorLoading = true
        this.dataLoaded = false
      }
    },
    loadImages() {
      if (this.item.images) {
        this.imagesLoading = true
        this.imageData = []
        let images = this.item.images.split(';')
        const promises = []
        images.forEach(image => {
          promises.push(new Promise(function(resolve, reject) {
            HTTP.get('damage-report/image/' + image, { responseType: 'arraybuffer' }).then(function (response) {
              resolve({
                'imageData': 'data:image/jpeg;base64,' + new Buffer(response.data, 'binary').toString('base64')
              }) 
            })
            .catch(function () {
              reject('rejected')
            })
          }))
        })
        Promise.allSettled(promises).then((results) => {
          results.forEach((result) => {
            if (result.value !== 'rejected') {
              this.imageData.push(result.value.imageData)
            }
          })
          this.imagesLoading = false
        })
      }
    },
    countLoadedImages() {
      this.loadedImages++
      if (this.loadedImages === this.imageData.length) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$redrawVueMasonry('images')
            setTimeout(() => {
              this.showImages = true
            }, 250)
          }, 100)
        })
      }
    },
    saveDamageReport() {
      return HTTP.patch('damage-report/' + this.itemId, this.item)
    },
    onSaved() {
      this.cancel()
    },
    deleteDamageReport() {
      return HTTP.delete('damage-report/' + this.itemId)
    },
    onDeleted() {
      this.cancel()
    },
    cancel() {
      this.$router.push({ name: 'DamageReportIndex' })
    },
    downloadImage(index) {
      if (typeof this.imageData[index] === 'undefined') {
        return
      }
      let fileName = "DamageReportImage_"
          + moment(this.item.created_at).format('YYYY_MM_DD') + "_"
          + this.item.user.firstname + "_"
          + this.item.user.lastname

      const downloadLink = document.createElement("a")
      downloadLink.href = this.imageData[index]
      downloadLink.download = fileName
      downloadLink.click()
    }
  }
}
</script>

<style scoped lang="scss">
.dr-image-wrapper {
  opacity: 0;
  transition: opacity 0.3s;

  &.show {
    opacity: 1;
  }

  .dr-image {
    width: 50%;
    padding: 8px;

    .v-btn {
      position: absolute;
      top: 16px;
      right: 16px;
      min-width: auto;
      width: 36px;
      background: #DCEAF5;
    }
  }
}

.status-list ::v-deep .v-input--radio-group__input {
  gap: 16px;
}
</style>

